
$primary-color:   #2196F3 !default;
$primary-text-color:   #FFFFFF !default;

.MuiFab-primary{
    color : $primary-text-color !important;
}

.MuiInput-underline:after{
    border-bottom : 2px solid $primary-color !important;
}

.MuiButton-textPrimary,
.MuiCheckbox-colorPrimary.Mui-checked,
.MuiTab-textColorPrimary.Mui-selected{
    color : $primary-color !important;
}

.MuiButton-containedPrimary,
.MuiPickersDay-isSelected,
.MuiPickersToolbar-toolbar,
.MuiFab-primary,
.MuiTabs-indicator{
    background-color: $primary-color !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color : $primary-color !important;
}

.MuiInputBase-root.Mui-disabled {
    background-color: rgba(33, 33, 33, 0.08) !important;
    color: #000000 !important;
}

.MuiFormHelperText-root,
.MuiFormHelperText-root.Mui-disabled
{
    color: #000000 !important;
}
.MuiInputLabel-outlined{
    color: #000000 !important;
}

.MuiFormLabel-root.Mui-focused {
    color: $primary-color !important;
}

.blue ul.nav-menu li.menu.open .sub-menu li > a.active{
    background-color: rgba(33,150,243,.25) !important;
}

.optionPopOver {
    padding: 0%;
    background: #F2F2F2;
}

.optionBlock {
    padding-block: inherit !important;
}

.optionRight {
    padding-right: inherit;
}

.borderedIconTextField{
    border: 1px solid #AAAAAA;
    border-radius: 8px;
    padding: 6px;
}

.surveySearchContainer .Mui-focused  {
    border: 1px solid $primary-color !important;
}

.surveySeriesSearchField{
    background-color: #F2F2F2 !important;
}

.surveyLineGraphLineInfo{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
}

.quick_insight_graph_label{
    width: calc(100 - 270px);
    text-align: center;
    padding-top: 16px;
    padding-bottom: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #646464;
    padding-left: 4%;
    margin-left: 270px;
}

.blue .app-main-header {
    background-color: #FFFFFF !important;
}

.app-data-container{
    margin-top: 70px;
}

.nav_bar_app_name {
    padding-left: 10px;
    padding-top: 4px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.18px;
    color: #000000;
}

.nav_bar_app_sub_name{
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: #4F4F4F;
    padding-left: 22px;
}

.side-nav{
    overflow: hidden !important;
}

.app_header_title{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
}

.MultiSelectDropDown {
    position: relative;
    width: 100%;
    min-height: 72px;
    padding: 0;
}

.MultiSelectDropDownChild {
    position: absolute;
    left: 0;
    top: 16px;
    right: 0;
    bottom: 0;
    padding: 0;
    border: 1px solid #C4C4C4;
    border-radius: 4px;
    background-color: #FFFFFF;
    align-items: center;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.15008px;
    line-height: 19.0016px;
    overflow-wrap: break-word;
    text-align: left;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.MultiSelectDropDownHeader {
    position: absolute;
    font-size: 1rem;
    font-weight: 400;
    color: #000000;
    background-color: #FFFFFF;
    padding-left: 8px;
    padding-right: 8px;
    transform: translate(0px, 3px) scale(0.75);
}

.MultiSelectDropDownHeaderActive {
    color: $primary-color !important;
}

.MultiSelectDropDownChildActive {
    border: 1px solid $primary-color !important;
    color: #000000 !important;
}

.MultiSelectDropDownContent {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.7) !important;
    padding-left: 16px;
}

.MultiSelectDropDownContentCheckBox {
    padding: 0 8px 0 0 !important;

}

.MultiSelectDropDownItem {
    padding-left: 0!important;
    padding-right: 0!important;
}

.MuiPopover-paper{
    max-height: 400px !important;
}

.tbl_header_clamp {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    line-height: 20px !important;
}