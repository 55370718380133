.surveyTranslationsUploadIcon {
  display: block;
  height: 36px;
  /*width: 36px;
  margin-left: auto;
  margin-right: auto;*/
  margin-top: 4px;
  background: url("/vendors/wovo/icons/upload.svg") no-repeat center center;

  background-size: 36px 36px;
  width: auto;
  min-width: 30rem;
  background-position-x: left;
}

.uploadIcon {
  background: url("/vendors/wovo/icon_upload.svg") no-repeat center center;
  background-size:contain
}

.downloadIcon {
  background: url("/vendors/wovo/icon_download.svg") no-repeat center center;
  background-size:contain
}

.survey_info_widget .ls_icons {
  display: block;
  width: 20px;
  height: 20px;
  background: #DADADA;
  background-size: 100% 100% !important;
}

.survey_info_widget .ls_icon_calender {
  background: url("/vendors/wovo/icons/survey_info/calender.svg") no-repeat center center;
}

.survey_info_widget .ls_icon_questions {
  background: url("/vendors/wovo/icons/survey_info/list.svg") no-repeat center center;
}

.survey_info_widget .ls_icon_languages {
  background: url("/vendors/wovo/icons/survey_info/globe.svg") no-repeat center center;
}

.survey_info_widget .ls_icon_surveys {
  background: url("/vendors/wovo/icons/survey_info/survey.svg") no-repeat center center;
}

.survey_info_widget .ls_icon_responses_device {
  background: url("/vendors/wovo/icons/survey_info/mobile_device.svg") no-repeat center center;
}

.survey_info_widget .ls_icon_categories {
  background: url("/vendors/wovo/icons/survey_info/categories.svg") no-repeat center center;
}

.survey_info_widget .ls_icon_companies {
  background: url("/vendors/wovo/icons/survey_info/companies.svg") no-repeat center center;
}

.survey_questionnaire_list .ls_icon_delete {
  background: url("/vendors/wovo/icons/survey_list/delete.svg") no-repeat center center;
}

.survey_questionnaire_list .ls_icon_report {
  background: url("/vendors/wovo/icons/survey_list/view_report.svg") no-repeat center center;
}

.survey_questionnaire_list .ls_icons {
  width: 20px !important;
  height: 20px !important;
  position: absolute;
}

.survey_navigation_icon_back {
  position: absolute;
  width: 20px !important;
  height: 20px !important;
  padding: 18px !important;
  background: url("/vendors/wovo/icons/survey_list/navigation_back.svg") no-repeat center center;

}


.survey_navigation_icon_forward {
  position: absolute;
  width: 20px !important;
  height: 20px !important;
  padding: 18px !important;
  background: url("/vendors/wovo/icons/survey_list/navigation_forward.svg") no-repeat center center;
}

.iconPdf{
  background: url("/vendors/wovo/icon_pdf.png") no-repeat center center;
  background-size:contain
}

.quick_insight_graph_icon_container {
  position: relative;
  width: 35px !important;
  height: 35px !important;
  margin-right: 8px;
}

.quick_insight_graph_icon {
  position: relative;
  width: 35px !important;
  height: 35px !important;
  cursor: pointer;
}

.quick_insight_graph_icon_bar{
  background: url("/vendors/wovo/icons/quick_insight/icon_bar_chart.svg") no-repeat center center;
  background-size:contain
}

.quick_insight_graph_icon_bar_active{
  background: url("/vendors/wovo/icons/quick_insight/icon_bar_chart_active.svg") no-repeat center center;
  background-size:contain
}

.quick_insight_graph_icon_pie{
  background: url("/vendors/wovo/icons/quick_insight/icon_pie_chart.svg") no-repeat center center;
  background-size:contain;
}

.quick_insight_graph_icon_pie_active{
  background: url("/vendors/wovo/icons/quick_insight/icon_pie_chart_active.svg") no-repeat center center;
  background-size:contain;
}

.quick_insight_graph_icon_line{
  background: url("/vendors/wovo/icons/quick_insight/icon_line_chart.svg") no-repeat center center;
  background-size:contain;
}

.quick_insight_graph_icon_line_active{
  background: url("/vendors/wovo/icons/quick_insight/icon_line_chart_active.svg") no-repeat center center;
  background-size:contain;
}

