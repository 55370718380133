.center_image {
  background: url("/vendors/moodle_registration/moodle_register_image.svg") no-repeat center center;
  background-size: contain;
}

.login_flow_center_image {
  background: url("/vendors/moodle_registration/moodle_login_flow_image.svg") no-repeat center center;
  background-size: contain;
}

.wovo_logo {
  background: url("/vendors/moodle_registration/wovo_logo.svg") no-repeat center center;
  background-size: contain;
}

.laborsolution_logo {
  background: url("/vendors/moodle_registration/laborsolution_logo.svg") no-repeat center center;
  background-size: contain;
}

.success_logo {
  background: url("/vendors/moodle_registration/success_logo.svg") no-repeat center center;
  background-size: contain;
}

.simple_wovo_logo {
  background: url("/vendors/moodle_registration/simple_wovo_logo.svg") no-repeat center center;
  background-size: contain;
}

.reset_pin_success_logo {
  background: url("/vendors/moodle_registration/reset_pin_success_logo.svg") no-repeat center center;
  background-size: contain;
}

.edit_icon {
  background: url("/vendors/moodle_registration/edit_icon.svg") no-repeat center center;
  background-size: contain;
}

.edit_button_container {
  width: 10px;
  height: 10px;
}

.logo_container {
  height: 48px;
}

.image_container {
  background: #F8F7FA;
}

.left_panel {
  background: #FFFFFF;
  border-radius: 0px 16px 16px 0px;
}

.heading_note {
  font-weight: 600;
  color: #333333;
  line-height: 29px;
}

.sub_note {
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  color: #8D8D8D;
  margin-top: -15px;
}

.button_container {
  justify-content: center;
  align-items: center;
  flex-grow: 1 !important;
}

.button_active {
  background: #2196F3;
  color: #FFFFFF;
}

.button_cancel {
  color: #2196F3;
  background: #FFFFFF;
}

.button_common {
  font-style: normal;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  border-radius: 4px;
  border: none;
  height: 30px;
}

.ls_logo_container {
  width: 236.5px;
  height: 20px;
}

.wovo_logo_container {
  width: 162px;
  height: 48px;
}

.center_Image_container {
  width: 100%;
  height: auto;
}

.footer_note {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px;
}

.policy_note {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
}

.popup_container {
  justify-content: center;
  align-items: center;
}

.cell_view {
  width: 40;
  margin: 5;
  justify-content: center;
  align-items: center;
  border-bottom-width: 1.5;
}

.MuiMobileStepper-dot {
  width: 12px !important;
  height: 12px !important;  
  background-color: #CBE7FF !important;
  margin: 0 2px;
  border-radius: 50%;
}

.MuiMobileStepper-root {
  background: #FFFFFF !important;
}

.MuiMobileStepper-dotActive {
  background-color: #2196F3 !important;
}

.MuiInputLabel-outlined {
  color: #8D8D8D !important;
}

.description {
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  color: #8D8D8D;
}

.MuiDialog-paperWidthSm {
  min-width: 300px;
}