.company-user-wrapper {
    padding: 6px;
    color: #FFFFFF;
}
.connect-tab-block  {
    .MuiAppBar-colorPrimary {
        color: #fff;
        background-color: transparent;
        box-shadow: none;
    }
    .MuiTab-root {
        border-color:transparent;
    }
}
.case-user-info-row:not(:last-child) {
    margin-bottom: 24px;
}
.case-user-info-row {
    width: 100%;
    display: table;
    color: #000000;
    padding: 6px;
    text-align: left;
    border: solid 1px #cccccc;
    border-radius: 3px;
    box-shadow: 0px 2px 3px #00000017;
    min-width: 80px;
}
.case-value {
    font-size: 20px;
    font-weight: bold;
    color: #004dff;
}
.side-header {
    margin-top: 32px;
    font-size: 1.2rem;
    width: 100%;
    margin-bottom: 24px;
    margin-left: 16px ;
}
h6.side-header {
    font-size: 18px;
}
.case-head {
    color: #8a8a8a;
    padding-bottom: 3px;
}
.max-min-container{
    text-align: left;
    border: solid 1px #cccccc;
    border-radius: 3px;
    box-shadow: 0px 2px 3px #00000017;
    padding:14px;
    text-align:center;
}
.min-container {
    margin-top:24px;
}
.classification-block {
    padding:24px 24px 24px 0px;
    font-size:16px
}
.classification-block label {
    padding-right:12px;
}
.v-center {
    align-items:center;
}
.badge-tab-container .MuiTabs-indicator {
    display:none;
}
.info-para {
    font-size: 12px;
    color: #8e8e8e;
}
.badge-tab-container {
    margin-top:24px;
    button.Mui-selected {
        background-color: #2196F3 !important;
        color: #fff !important;
    }
    .tab-link-row {
        height:35px;
        padding-left: 0px;
    }
    button.MuiButtonBase-root {
        padding: 3px 12px;
        overflow: hidden;
        position: relative;
        font-size: 0.70rem;
        max-width: 264px;
        min-width: 72px;
        box-sizing: border-box;
        text-align: center;
        flex-shrink: 0;
        font-family: "Roboto", sans-serif, "Helvetica", "Arial" !important;
        font-weight: 700 !important;
        line-height: 1.75;
        white-space: normal;
        letter-spacing: 0.02857em;
        text-transform: initial;
        height: auto;
        min-height: 0;
        border-radius: 20px;
        color: #212529;
        background-color: #e8eaf6;
    }
}
.chart-header {
    font-weight: 600;
    font-size: 16px;
}
.chart-container-row,.case-type-overtime{
    align-items: baseline;
}
.connect-tab-block select {
    border: solid 1px #1b1b1b;
    border-radius: 5px;
}

.connect-tab-block .MuiInput-root {
    position: relative;
    display: block;
}
.connect-tab-block .MuiFormLabel-filled{
    transform: translate(0, -11.5px) scale(0.95);
}
.case-management-block {
    position: relative;
}
.case-management-selection-list {
    width: 200px;
    position: absolute;
    z-index: 10;
}
.hidden {
    display: none;
}
.case-value-italics {
    font-style: italic;
}
.footer-row {
    margin-top: 32px !important;
    align-items: baseline;
}
.case-management-table {
    .MuiTableCell-root {
        padding:12px 6px;
    }
}
.case-management-table .MuiTableCell-root {
    padding: 8px 6px;
    font-size: 10px;
    line-height: 12px;
}
.case-management-block p {
    font-size: 10px;
    padding: 12px;
}
.case-management-block p.p-italic-txt {
    font-style: italic;
}
.connect-tab-block label {
    font-weight: bold;
    text-transform: capitalize;
}
.case-flex{
    display: flex;
}
.case-value-italics.case-flex {
    font-size: 10px;
    text-align: left;
}
.case-value-italics.case-flex > div:first-child {
    position: relative;
}
.case-value-italics.case-flex > div:first-child::before {
    content: " ";
    display: block;
    width: 1px;
    background: #a5a5a5;
    height: 26px;
    position: absolute;
    right: 0px;
}
.case-value-italics.case-flex > div:last-child {
    position: relative;
    padding-left: 6px;
}
.no-data-loaded-chart {
    font-size: 20px;
    padding-left: 30px;
}
.case-popularity-wrapper .case-value {
    font-size: 12px;
}
.smile-icon::before {
    content: '\1F642';
    font-size: 16px;
    position: relative;
    top: 2px;
}
.sad-icon::before {
    content: '\1F615';
    font-size: 16px;
    position: relative;
    top: 2px;
}
.inline-select-row.MuiFormControl-root {
    display: flex;
    flex-direction: row;
    align-items: center;
    > label {
        margin-right: 30px;
    }
    .select-form-wrap {
        width: 200px;
        margin-top: 0;
    }
}
.empty-cells {
    background-color: #5b4560  !important;
    color:#5b4560  !important;
}
.summary-table-block .MuiTableCell-alignLeft {
    white-space: pre !important;
}
th.empty-cells.MuiTableCell-alignLeft {
    border-color: #5b4560;
    border-width: 0px 0px 0px 0px;
}
.summary-table-block .per_100_contacts {
    display: none;
}