.fontSizeRem2 {
    font-size: 2rem !important;
}

.surveySource {
    /*border-style: dotted;*/
    border-width: 2px;
    cursor: pointer;
    /*border-color: #007bff;*/
}

.surveySourceActive {
    border-style: solid;
    border-width: 5px;
    cursor: pointer;
    /*border-color: #007bff;*/

    border-color: #1fb6fc !important;
}

.hand {
    cursor: pointer;
}

.questionLayoutTopBorder {
    border-top: 10px solid #1fb6fc !important;
}

.surveyPosterParent {
    width: 20rem;
    position: relative;
}

.surveyPosterLabelParent {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
}

.surveyMediaPoster {
    position: relative;
    height: auto !important;
    min-width: 20rem !important;
    max-width: 20rem !important;
    min-height: 10rem;
    overflow: hidden;
    border: 1px solid rgba(33, 150, 243, 1) !important;
}

.displayNone {
    display: none !important;
}

.surveySupportedLanguagesDropDown {
    margin-bottom: -4rem !important;
    color: #0c0f11 !important;
}

.white {
    color: #FFFFFF !important;
}

.black {
    color: #0c0f11 !important;
}

.hover-button .hover-button--on,
.hover-button:hover .hover-button--off {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.hover-button:hover .hover-button--on {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin : 0 !important;
}

.rightContainer {
    position: absolute;
    right: 0;
}

.reportsSubContainerMarginTop {
    margin-top: -54px !important;
}


.surveyInfoStatusBadge{
    position: absolute;
    top: -1.15rem;
    left: 3%;
}

.eLearningSecondaryBadge {
    background-color: rgba(91, 69, 96, 0.2) !important
}

.surveyCloneWindow{
    max-width: 80% !important;
    min-width: 80% !important;
}

.surveyEndDateClear{

    margin-left: 5%;
    color: rgba(33, 150, 243, 1);
    cursor: pointer;
}

.sv_next_btn, .sv_complete_btn{
    /* margin-top: 3% !important; */
    background-color: #4caf50!important;
    margin-right: 5px !important;

    padding: .6rem 1.75rem .6rem 1.75rem !important;
    line-height: 1.2!important;
    min-width: 10px!important;
    border: transparent;
    min-height: 10px!important;
    font-size: .875rem!important;
    font-weight: 400!important;
    text-transform: capitalize!important;
    border-radius: .125rem!important;
}

.sv_prev_btn{
    margin-right: 2rem !important;
}

.button_disabled {
    opacity : 0.7
}

div[tabindex="-1"] {
    z-index: 0 !important;
}

.translationsDropDownContainer {
    min-height: 20rem !important;
}

.loaderstyle {
    color:red;
}

.cloudDownloadIcon svg{
   color: #A9A9A9;
}

#questionnaireList::-webkit-scrollbar,
#surveyList::-webkit-scrollbar {

    width: 0px;
    background: transparent;
}

#questionnaireList span,
#surveyList scroll_container{
    position: relative;
    width: 100%;
}

.summaryTableScrollHeader .public_fixedDataTable_header,
.summaryTableScrollHeader .public_fixedDataTable_scrollbarSpacer,
.summaryTableScrollHeader .public_fixedDataTable_header .public_fixedDataTableCell_main {
background-color: #2196F3 !important;
background-image: linear-gradient(#2196F3, #2196F3) !important;
color: #FFFFEF !important;
}

.reportFilterDivider{
    border-top: 1px solid #AAAAAA;
}

.surveyErrorDot{
    position: absolute;
    height: 12px;
    width: 12px;
    right: 0.75rem;
    top: 12px;
    background: url("/vendors/wovo/icons/red_dot.svg") no-repeat center center;
    background-size: 100% 100%;
}

.surveyLanguageErrorDot {
    position: absolute;
    height: 12px;
    width: 12px;
    right: 0px;
    top: 14px;
    background: url("/vendors/wovo/icons/red_dot.svg") no-repeat center center;
    background-size: 100% 100%;
}

.reportFilterIcon{
    position: absolute;
    right: 1.5rem;
    height: 24px;
    width: 24px;
    background: url("/vendors/wovo/dropdown_icon.svg") no-repeat center center;
}

.reportFilterDataIcon{
    display: inline-block;
    margin-right: 6px;
    height: 20px;
    width: 20px;
    vertical-align: bottom;
}

.chartIcon{
    background: url("/vendors/wovo/chart_icon.svg") no-repeat center center;
    background-size:contain
}

.chartIconBlue{
    background: url("/vendors/wovo/chart_icon_blue.svg") no-repeat center center;
    background-size:contain
}

.tableIcon{
    background: url("/vendors/wovo/table_icon.svg") no-repeat center center;
    background-size:contain
}

.tableIconBlue{
    background: url("/vendors/wovo/table_icon_blue.svg") no-repeat center center;
    background-size:contain
}

.excelIcon{
    background: url("/vendors/wovo/rawdata_icon.svg") no-repeat center center;
    background-size:contain
}

.downloadlIconBlue{
    background: url("/vendors/wovo/download_icon_blue.svg") no-repeat center center;
    background-size:contain
}

.reportFilterMenuArrow{
    background: url("/vendors/wovo/arrow_icon.svg") no-repeat center center;
    width: 1.5em;
    height: 1.5em;
    display: inline-block;
    align-self: center;
}

.reportFilterTitle {
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    font-weight : 600;
    color: #4F4F4F;
}

.reportFilterDemographic{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #4F4F4F;
}

.reportFilterIcon_flip {
    transform: rotate(180deg) ;
    transition: width 1s;
}

.transparentButton {
    box-shadow: 0 0 transparent !important;
    color: #2196F3 !important;
    background-color: transparent;
    font-weight: 500;
}

.reportTitleLogo{
    position: absolute;
    height: 2.5rem;
    width: 2.5rem;
    background: url("/vendors/wovo/wovo.svg") no-repeat center center;
    background-size: contain;
}

.reportTitle{
    font-style: normal;
    font-weight: normal;
    font-size: 1.9rem;
    line-height: 1.9rem;
    display: flex;
    align-items: center;
    margin-left: 2.8rem;
    color: #4F4F4F;
    margin-top: 0.4rem;
}

.reportTitleDivider{
    border-bottom: 1px solid #AAAAAA;
}

.reportTitleCalenderIcon{
    position: absolute;
    right: 0rem;
    top: 0.75rem;
    height: 24px;
    width: 24px;
    background: url("/vendors/wovo/reports_time_range.svg") no-repeat center center;
    cursor: grab ;

}

.reportTitleCalenderContainer{
    position: absolute;
    right: 36px;
    top: 1rem;
    cursor: grab ;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4F4F4F;
}

.DateRangeWrapper {
    width: 100% !important;
}

.connectReportChips{
    right: 0px;
    width: auto;
    position: absolute;
}

.connectReportChipsTitle{
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    color: #4F4F4F;

}

.reportTileTitle{
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.4rem;
    display: flex;
    align-items: center;
    color: #4F4F4F;
    margin-top: 0.4rem;
}

.dashboardtiles .net-chart{
    min-height: 150px !important;
}

.dashboardtiles .jr-card-body{
    width: 100%;
}

.dashboardtiles .title{
    font-size: 1.3rem;
}

.dashboardtiles .subtitle{
    font-size: 0.9rem;
}

.dashboardtiles .extraTitle{
    font-size: 0.9rem;
}

.dashboardtiles .extraSubTitle{
    font-size: 0.7rem;
}

#jsd-widget{
    margin-right: 12px;
}

.eLearningValues{
    font-weight: 600;
}

.reportStickerBronze{
    position: absolute;
    right: 1.5rem;
    height: 24px;
    width: 24px;
    background: url("/vendors/wovo/bronze_poly.svg") no-repeat center center;
}

.reportStickerBronzeInline{
    position: absolute;
    left: 0;
    height: 24px;
    width: 24px;
    background: url("/vendors/wovo/bronze_poly.svg") no-repeat center center;
}

.reportStickerSilver{
    position: absolute;
    right: 1.5rem;
    height: 24px;
    width: 24px;
    background: url("/vendors/wovo/silver_poly.svg") no-repeat center center;
}

.reportStickerSilverInline{
    position: absolute;
    left: 0;
    height: 24px;
    width: 24px;
    background: url("/vendors/wovo/silver_poly.svg") no-repeat center center;
}

.reportStickerGold{
    position: absolute;
    right: 1.5rem;
    height: 24px;
    width: 24px;
    background: url("/vendors/wovo/gold_poly.svg") no-repeat center center;
}

.reportStickerGoldInline{
    position: absolute;
    left: 0;
    height: 24px;
    width: 24px;
    background: url("/vendors/wovo/gold_poly.svg") no-repeat center center;
}

.stickerLabelRow {
    height: 40px;
    margin-top: 8px;
}

.stickerLabelContainer{
    position: absolute;
    right: 0;
}

.stickerLabelInnerContainer{
    position: relative;
    margin-left:24px;
}

.stickerLabel{
    position: relative;
    margin-left: 32px;
    font-size: 1rem;
}

.stickerLabelBronze{
    color: #F3B27C;
}

.stickerLabelSilver{
    color: #B3B3B3;
}

.stickerLabelGold{
    color: #E7C16C;
}

.clientModulesTitle{
    font-weight: 600;
    font-size: 1rem !important;
}

.clientModulesTitleParent{
    margin-top: -2rem !important;
}

.MuiInputBase-input{
    letter-spacing: normal !important;
}

.mandatory_star{
    color : red;
    padding-left: 1rem;
    font-size: 1.2rem;
}

.optionBuilderIcon{
    background: url("/vendors/wovo/option-builder.svg");
    width: 24px;
    height: 24px;
}

.optionBuilderIconGray {
    filter: grayscale(100%);
}

.text_color_primary{
    color: #2196F3 !important;
}

.text_color_danger{
    color: #FB2424 !important;
}

.button_text_color_danger{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #FB2424;
}

.button_text_color_primary{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #2196F3;
}

.button_text_color_disabled{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #AAAAAA !important;
}

.button_text_color_white{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.text_color_white{
    color: #FFFFFF !important;
}

.button_primary {
    background-color: #2196F3 !important;
    border: 1px solid #2196F3 !important;
    box-shadow: none !important;
    border-radius: 4px !important;
}

.button_primary_border {
    background-color: #FFFFFF !important;
    border: 1px solid #DFDBDB !important;
    box-shadow: none !important;
    border-radius: 4px !important;
}
.survey_translations_layout_title{
    font-weight: 400 !important;
    color: #000000;
    font-size: 1.5rem;
    line-height: 1.6rem;
}

.survey_translations_layout_info{
    font-weight: 400 !important;
    color: #000000;
    font-size: 1rem;
    line-height: 1.1rem;
}

.survey_info_widget .card-header{
    padding-left: 18px;
    padding-right: 18px;
}

.survey_info_widget .header_title{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #4F4F4F;
    padding-left: 14px;
    width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tooltip_style{
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4rem;
    letter-spacing: 1px;
    color: #FFFFFF;
    padding-left: 14px;
}

.popup_content{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.15px;
    color: #4F4F4F;
}


.survey_status_badge{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #FFFFFF;

    align-items: center;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 8px 8px 6px 8px !important;
}

.survey_info_widget .survey_language_badge{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #000000;
    align-items: center;
    padding: 5px 0px 3px 8px;
    border-radius: 4px;
    letter-spacing: 0.4px;
}

.survey_info_widget .right_action_container{
    position: absolute;
    right: 0;
    top: 0.7rem;
    padding-right: 18px;
}

.survey_info_widget_info_tile{
    height: 94px !important;
}

.survey_info_widget_responses{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.1px;
    color: #4F4F4F;
    line-height: 94px;
}

.survey_info_widget_responses_count{
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.1px;
    color: #4F4F4F;
    line-height: 94px;
    font-size: 48px;
}

.survey_info_widget_qr_code_img{
    height: 50px;
    margin-top: 22px;
    margin-left: 28px;
    position: relative;
}

.survey_info_widget_qr_code_button_container{
    height: 50px;
    left: 100px;
    right: 0;
    position: absolute;
    float: right;
    top:28px;
    z-index: 1000;
}


.survey_info_widget  .draft{
    background-color: #F9B621 !important;
}

.survey_info_widget  .active{
    background-color: #3AB55C !important;
}

.survey_info_widget  .inactive{
    background-color: #575757 !important;
}

.survey_info_widget  .deleted{
    background-color: #575757 !important;
}

.survey_info_widget  .scheduled{
    background-color: #2196F3 !important;
}

.survey_info_widget  .completed{
    background-color: #575757 !important;
}

.survey_info_widget .icon_info_header{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #AAAAAA;
}

.survey_info_widget .icon_info_content{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #4F4F4F;
/*    margin-left: 32px;*/
}

.survey_info_widget_popover_text{
    min-width: 80px;
    padding-left: 8px;
    padding-right: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #4F4F4F;
}

.survey_info_widget_redeploy_text{
    min-width: 80px;
    padding-left: 8px;
    padding-right: 8px;
    font-style: normal;
    font-weight: normal;
    color: #4F4F4F;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
}

.survey_info_widget_redeploy_text_selected {
    font-weight: 500 !important;
}


.survey_translations_dialog .MuiDialog-paper{
    min-width: 80% !important;
}

.survey_translations_dialog .MuiButton-label{
    color: #2196F3 !important;
    font-weight: 500 !important;
}

.survey_translations_dialog .dropzone-file-btn{
    width: 100% !important;
    border: 2px dashed #AAAAAA;
}

.survey_translations_dialog .infoLabel{
    position: absolute;
    font-style: normal;
    font-weight: 500;
    font-size: 1.35rem;
    line-height: 1.5rem;
    color: #AAAAAA;
    margin-left: 48px;
    letter-spacing: 0.15px;
}


.survey_translations_dialog .activeContentDialog{
    background: #E0F0FE;
    border: 2px dashed #2196F3;
    box-sizing: border-box;
}

.survey_translations_dialog .filesInfoLabel{
    position: absolute;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #2196F3;
    text-decoration: underline;
}

.survey_translations_dialog li{
    list-style-type: none;
}

.survey_translations_dialog .browse_button{
    display: block;
    height: 36px;
    margin-left: auto;
    margin-right: auto;
}

.survey_translations_dialog .drag_and_drop_container{
    position: relative;
    height: 100%;
    width: 100%;
}

.survey_translations_dialog .drag_and_drop_button_container{
    position: absolute;
    height: auto;
    width: 100%;
    bottom: 0;
}


.surveyListStatus{
    position : absolute;
    margin-top: -0.75rem !important;
}

.surveyStatusLabel{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.surveyStatusLabelDraft{
    background: #F9B621;
}

.surveyStatusLabelActive{
    background: #3AB55C;
    padding-left: 11px;
    padding-right: 11px;
}

.surveyStatusLabelClosed{
    background: #575757;
}


.surveyItemTile{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.surveyItemTileTextContainer{
    position: relative;
    width: 100%;
    height: 50px;
    margin-top: 32px;
}

.surveyItemTileTextContainerRightMargin{
    border-right: 1px solid #AAAAAA;
}


.surveyItemTileTextNumber{
    position: relative;
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #3F3F3F;
}

.surveyItemTileText{
    position: relative;
    width: 100%;
    text-align: center;
    letter-spacing: 0.15px;
    color: #3F3F3F;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-top: 4px;
}

.surveyItemTileStatusText{
    position: relative;
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #2196F3 !important;
    margin-left : 22px;
}


.surveyItemTileDeleteText{
    position: relative;
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #B00020 !important;
    margin-left : 22px;
}

.surveyItemTileIconContainer{
    position: absolute;
    top: 18px;
    left:20%;
}

.surveyItemTileIconTextContainer{
    position: absolute;
    top: 18px;
}

.surveySeriesQuestionListContainer{
    height: 100px;
    margin-bottom: 1.5rem !important;
    cursor: pointer;
}

.surveySeriesQuestionsList{
    min-height: 400px;
    background-color: #F2F2F2 !important;
}

.surveyListContainer{
    height: 124px;
    margin-bottom: 1.5rem !important;
    cursor: pointer;
}

.surveyListContainer_no_margin_bottom{
    height: 124px;
    cursor: pointer;
}

.redeployedSurveyListContainer{
    height: 114px;
    cursor: pointer;
    margin-top: 4px !important;
}

.redeployedSurveyListContainerItem{
    height: 100%;
    background-color: #F0EDED !important;
}

.redeployedTitleContainer{
    position: absolute;
    left:30px;
    right: 30px;
    top: 25px;
    bottom: 24px;
}

.margin_bottom{
    margin-bottom: 1.5rem !important;
}

.seriesQuestionCheckbox{
    position: absolute;
    right: 20px;
    top: 32px;
    float: right;
}

.seriesQuestionTypeTitle{
    position: absolute;
    left: 20px;
    right: 80px;
    top: 14px;
    bottom: 24px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #AAAAAA;
}

.seriesQuestionTitleContainer{
    position: absolute;
    left:20px;
    right: 80px;
    top: 44px;
    bottom: 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #4F4F4F;
}

.titleContainer{
    position: absolute;
    left:30px;
    right: 30px;
    top: 30px;
    bottom: 24px;
}

.surveyListTileName{
    position: relative;
    max-width:85%;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.18px;
    color: #4F4F4F;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
}


.surveyListTileQuestionnaireInfo{
    position: relative;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #B5B5B5;
    margin-top: 6px;
    height: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.surveyListTileDateInfo{
    position: relative;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #AAAAAA;
    margin-top: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.surveyNavigationContainer{
    position: relative;
    width: 100% !important;
}

.surveyNavigationSubContainer{
    position: relative;
    height: 78px !important;
}

.surveyNavigationBackButtonContainer{
    position: absolute;
    top:0;
    bottom:0;
    left: 0;
    right: 12px;
}

.surveyNavigationStatusContainer{
    position: absolute;
    top:0;
    bottom:0;
    left: 0;
    right: 0;
}

.alignCenter{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.horizontal_center{
    margin: 0;
    position: absolute;
    left: 50%;
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.vertical_center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.surveyNavigationStatus{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #8D8D8D;
}

.surveyNavigationStatusInactive{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #8D8D8D;
    opacity: 0.5;
}

.surveyNavigationStatusActive{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #2196F3 !important;
}

.survey_navigation_icon_forward{
    right: 0;
}

.wovo_cookie_banner {
    display: flex;
    color: #000;
    background-color: #fff;
    opacity: 1;
    transform: translateY(0);
    z-index: 2147483647;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 50px 0 50px;
}

.wovo_cookie_banner_content {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    align-content: center;
    margin-top: 0;
    margin-bottom: 0;
    justify-content: space-between;
    padding: 16px 0;
}

.wovo_cookie_banner_button {
    color: #fff;
    background-color: #000;
    border: solid 1px #000;
    text-overflow: ellipsis;
    margin-top: 0;
    margin-bottom: 0;
    height:32px;
    padding: 0 16px 0 16px;
}

.survey_navigation_filter_label{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #4F4F4F;
}

.quick_insights_ls_header{
    position: relative;
    left: 0;
    right: 0;
    height: 72px;
    width: 100%;
    border-top: 1px solid #AAAAAA ;
    border-bottom: 1px solid #AAAAAA ;
}

.quick_insight_ls_logo {
    position:relative;
    height: 44px;
    width : auto;
}

.quick_insights_ls_header_label{
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.18px;
    margin-left: 16px;
}

.quick_insights_ls_scoring_content{
    position: relative;
    width: 100%;
    border-bottom: 1px solid #AAAAAA ;
}
.quick_insights_ls_scoring_label{
    position: relative;
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #4F4F4F;
}

.quick_insights_ls_scoring_label_sup{
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #797979;
}

.quick_insights_ls_scoring_label_container{
    position: absolute;
    right: 0;
}

.report_scoring_rating_icon{
    position: relative;
    width: 22px;
    height: 22px;
    border-radius: 11px;
    margin-top: 10px;
    margin-right: 22px;
}

.report_scoring_rating_blue{
    background: #1141BB;
}

.report_scoring_rating_green{
    background: #097C14;
}

.report_scoring_rating_orange{
    background: #FFB300;
}

.report_scoring_rating_grey{
    background: #969696;
}

.report_scoring_rating_label{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
    position: absolute;
    float: left;
    left: 44px;
    padding-top: 4px;
}

.report_scoring_rating_label_blue{
    color: #1141BB;
}

.report_scoring_rating_label_green{
    color: #097C14;
}

.report_scoring_rating_label_orange{
    color: #FFB300;
}

.report_scoring_rating_label_container{
    position: relative;
    padding-top: 12px;
    padding-bottom: 28px;
}

.quick_insights_ls_scoring_number{
    position: relative;
    width: 7.5rem;
    height: 5rem;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 18px;
    color: #FFFFFF;
    border-radius: 6px;
}

.quick_insight_filter_container{
    position: absolute;
    right: 0;
    padding-top: 10px;
}

.quick_insight_question_title{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #4F4F4F;
}

.quick_insights_question_scoring_label_container{
    position: relative;
    width: auto;
    height: auto;
}

.quick_insights_question_scoring_label{
    position: relative;
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #4F4F4F;
}

.quick_insights_question_scoring_number{
    position: relative;
    width: 5.5rem;
    height: 3.5rem;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 18px;
    color: #FFFFFF;
    border-radius: 6px;
}

.quick_insights_question_container{
    border-bottom: 0.5px solid #AAAAAA;
    padding-bottom: 2.5%;
    margin-bottom: 2.5%;
}

.quick_insight_graph_label_vertical{
    padding-top: 8px;
    padding-bottom: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #646464;
    transform: rotate(-90deg);
    float: left;
    position: absolute;
    top: 9rem;
}

.quick_insight_graph{
    margin-left: 4%;
}

.quick_insight_graph_info_label{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #4F4F4F;
}

.ls_popup_title {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    letter-spacing: 0.15px !important;
    color: #4F4F4F !important;
}

.ls_popup_ok_button {
    background-color: #2196F3 !important;
    color: #FFFFFF !important;
    margin-bottom: 8px !important;
    margin-right: 8px !important;
    margin-left: 8px !important;
}

.ls_popup_ok_button_label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.ls_popup_cancel_button {
    color: #2196F3 !important;
}

.ls_popup_cancel_button_label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #2196F3;
}

.ls_survey_snack_bar {
    position: absolute;
    width: auto;
    background-color: rgba(0, 0, 0, 0.87);
    height: auto;
    z-index: 10000;
    right: 0;
    top: 0;
    color: #FFFFFF;
    border-radius: 4px;
}


.ls_survey_snack_bar .header{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
}

.ls_survey_snack_bar .content{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
}

.ls_text_ellipsis {
    position: relative;
    min-height: 40px;
    overflow: hidden !important;
}

.ls_text_ellipsis:before {
    content: '&nbsp;';
    visibility: hidden;
}

.ls_text_ellipsis_text {
    position: absolute;
    left: 0;
    right: 0;
    top:0;
    bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    padding-bottom: 20px;
}